import { useEffect, useRef } from 'react';

function useEffectOnce(cb) {
  const didRun = useRef(false);
  useEffect(() => {
    if (!didRun.current) {
      cb();
      didRun.current = true;
    }
  });
}

async function useEffectOnceAsync(cb) {
  const didRun = useRef(false);
  useEffect(() => {
    if (!didRun.current) {
      (async () => cb())();
      didRun.current = true;
    }
  });
}

const useInterval = (callback, delay) => {
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  });

  useEffect(() => {
    const tick = () => {
      savedCallback.current();
    };
    if (!delay) return () => {};
    const id = setInterval(tick, delay);
    return () => clearInterval(id);
  }, [delay]);
};

export { useEffectOnce, useEffectOnceAsync, useInterval };
